import React, { useCallback, useEffect, useState } from "react";
import {
  BannerPage,
  ContainerContentFixed,
  Layout,
  SEO,
  ContainerCta,
  InternalLinkTypes,
  ContainerContent,
  InternalLink,
  StoreyFilter,
  Filter,
  DisplayFloorPlanList,
} from "../components";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col, Space } from "antd";

import { Colors } from "../components";
import "./floorplan.css";

const FloorPlans = () => {
  const data = useStaticQuery(graphql`
    query {
      allFloorPlansJson {
        nodes {
          name
          slug
          displayLink
          info {
            size
            storey
            bed
            bath
            carSpace
            living
            lotWidth
            lotLength
          }
          imagePath {
            id
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const floorplans = data.allFloorPlansJson.nodes;

  const [selectedBed, setSelectedBed] = useState("all");
  const onChangeSelectedBed = useCallback(
    value => {
      setSelectedBed(value);
    },
    [setSelectedBed]
  );

  const [selectedBath, setSelectedBath] = useState("all");
  const onChangeSelectedBath = useCallback(
    values => {
      setSelectedBath(values);
    },
    [setSelectedBath]
  );

  const [selectedLiving, setSelectedLiving] = useState("all");
  const onChangeSelectedLiving = useCallback(
    values => {
      setSelectedLiving(values);
    },
    [setSelectedLiving]
  );

  const [selectedGarage, setSelectedGarage] = useState("all");
  const onChangeSelectedGarage = useCallback(
    values => {
      setSelectedGarage(values);
    },
    [setSelectedGarage]
  );

  const [selectedLotWidth, setSelectedLotWidth] = useState("all");
  const onChangeSelectedLotWidth = useCallback(
    value => {
      setSelectedLotWidth(value);
    },
    [setSelectedLotWidth]
  );
  const [selectedLotLength, setSelectedLotLength] = useState("all");
  const onChangeSelectedLotLength = useCallback(
    value => {
      setSelectedLotLength(value);
    },
    [setSelectedLotLength]
  );

  const [filterOptions] = useState(() => {
    let options = {
      beds: [{ value: "all", text: "All" }],
      baths: [{ value: "all", text: "All" }],
      living: [{ value: "all", text: "All" }],
      garage: [{ value: "all", text: "All" }],
      lotWidth: [{ value: "all", text: "All" }],
      lotLength: [{ value: "all", text: "All" }],
    };
    let beds = new Set();
    let baths = new Set();
    let living = new Set();
    let garage = new Set();
    let lotWidth = new Set();
    let lotLength = new Set();
    floorplans.forEach(fp => {
      beds.add(fp.info.bed);
      baths.add(fp.info.bath);
      living.add(fp.info.living);
      garage.add(fp.info.carSpace);
      lotWidth.add(fp.info.lotWidth);
      lotLength.add(fp.info.lotLength);
    });

    Array.from(beds)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.beds.push({ value: item, text: `${item}` }));

    Array.from(baths)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.baths.push({ value: item, text: `${item}` }));

    Array.from(living)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.living.push({ value: item, text: `${item}` }));

    Array.from(garage)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item => options.garage.push({ value: item, text: `${item}` }));

    Array.from(lotWidth)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item =>
        options.lotWidth.push({ value: item, text: `${item}m` })
      );

    Array.from(lotLength)
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .forEach(item =>
        options.lotLength.push({ value: item, text: `${item}m` })
      );

    return options;
  });

  const [filteredPlans, setFilteredPlans] = useState([]);

  const [selectedStorey, setSelectedStorey] = useState("all");

  const onChangeSelectedStorey = useCallback(
    value => {
      setSelectedStorey(value);
    },
    [setSelectedStorey]
  );

  useEffect(() => {
    let filtered = [];
    floorplans.forEach(plan => {
      if (
        (selectedStorey.toString() === plan.info.storey.toString() ||
          selectedStorey.toString() === "all") &&
        (selectedBed.toString() === plan.info.bed.toString() ||
          selectedBed.toString() === "all") &&
        (selectedBath.toString() === plan.info.bath.toString() ||
          selectedBath.toString() === "all") &&
        (selectedLiving.toString() === plan.info.living.toString() ||
          selectedLiving.toString() === "all") &&
        (selectedGarage.toString() === plan.info.carSpace.toString() ||
          selectedGarage.toString() === "all") &&
        (selectedLotWidth.toString() === plan.info.lotWidth.toString() ||
          selectedLotWidth.toString() === "all") &&
        (selectedLotLength.toString() === plan.info.lotLength.toString() ||
          selectedLotLength.toString() === "all")
      )
        filtered.push(plan);
    });

    setFilteredPlans(filtered);
  }, [
    floorplans,
    selectedStorey,
    selectedBed,
    selectedBath,
    selectedLiving,
    selectedGarage,
    selectedLotWidth,
    selectedLotLength,
  ]);
  return (
    <div className={"cp-floorplans"}>
      <Layout>
        <SEO
          title="Floor Plans"
          description={"Browse through our existing floor plans"}
        />
        <BannerPage
          title={"Floor Plans"}
          subTitle={"Browse through our existing floor plans"}
        />

        <ContainerContent
          style={{
            backgroundColor: Colors.LightGray,
            paddingTop: 0,
          }}
        >
          <ContainerContentFixed>
            <Space direction="vertical" size={16}>
              <Row gutter={[16, 16]}>
                <Col>
                  <StoreyFilter
                    selectedFilter={selectedStorey}
                    onChangeFilter={onChangeSelectedStorey}
                  />
                </Col>
                <Col>
                  <Filter
                    label={"Bedroom"}
                    selectedFilter={selectedBed}
                    onChangeFilter={onChangeSelectedBed}
                    filterOptions={filterOptions.beds}
                  />
                </Col>
                <Col>
                  <Filter
                    label={"Bathroom"}
                    selectedFilter={selectedBath}
                    onChangeFilter={onChangeSelectedBath}
                    filterOptions={filterOptions.baths}
                  />
                </Col>
                <Col>
                  <Filter
                    label={"Living area"}
                    selectedFilter={selectedLiving}
                    onChangeFilter={onChangeSelectedLiving}
                    filterOptions={filterOptions.living}
                  />
                </Col>
                <Col>
                  <Filter
                    label={"Car Space"}
                    selectedFilter={selectedGarage}
                    onChangeFilter={onChangeSelectedGarage}
                    filterOptions={filterOptions.garage}
                  />
                </Col>
                <Col>
                  <Filter
                    label={"Fits Lot Frontage"}
                    selectedFilter={selectedLotWidth}
                    onChangeFilter={onChangeSelectedLotWidth}
                    filterOptions={filterOptions.lotWidth}
                  />
                </Col>
                <Col>
                  <Filter
                    label={"Fits Lot Depth"}
                    selectedFilter={selectedLotLength}
                    onChangeFilter={onChangeSelectedLotLength}
                    filterOptions={filterOptions.lotLength}
                  />
                </Col>
              </Row>
              <DisplayFloorPlanList floorplans={filteredPlans} />

              <section style={{ textAlign: "center" }}>
                <InternalLink url={InternalLinkTypes.Disclaimer}>
                  See our disclaimer
                </InternalLink>
              </section>
            </Space>
          </ContainerContentFixed>
        </ContainerContent>
        <ContainerCta
          title={"Can't find the floor plan you like?"}
          subTitle={"Don't worry, we can build one as per your needs"}
          primaryText={"Customise Your Own"}
          primaryLink={InternalLinkTypes.GetStarted}
          secondaryLink={InternalLinkTypes.Facades}
          secondaryText={"See Facades"}
        />
      </Layout>
    </div>
  );
};

export default FloorPlans;
